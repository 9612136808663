import React, { useContext } from 'react';
import { Circle, Tooltip } from 'react-leaflet';
import context from '../context';
// import { getPointParams } from '../tools';

const Circles = React.memo(({ data, typeOfRouter, selectedSpaces, onCircleClick }) => {
    const { state, dispatch } = useContext(context);

    const onRightClick = (id, typeOfRouter) => e => {
        const cursorCoords = {
            x: e.originalEvent.layerX,
            y: e.originalEvent.layerY
        };
        dispatch({
            type: 'handleRightSectorClick',
            payload: { sectorId: id, typeOfRouter, cursorCoords }
        });
    };

    try {
        // const filteredData =
        //     state.selectedPL.length === 0 ? data : data.filter(item => item.project_location === state.selectedPL[0]);

        return data.map((item, i) => {
            // const { circleColor, radius, tooltip } = getPointParams(item, typeOfRouter, selectedSpaces);

            return (
                <Circle
                    key={`c-${item.latitude}-${item.longitude}`}
                    id={item.id}
                    center={{ lat: Number(item.latitude), lng: Number(item.longitude) }}
                    fillColor={'#fff'}
                    fillOpacity={1}
                    color={'transparent'}
                    radius={5}
                    // onContextMenu={onRightClick(item.id, typeOfRouter)}
                    // onClick={onCircleClick(item.id, typeOfRouter)}
                >
                    {/* <Tooltip key={i}>{tooltip}</Tooltip> */}
                </Circle>
            );
        });
    } catch (error) {
        return null;
    }
});

export default Circles;
