import React from 'react';

const Context = React.createContext({
  data: {},
  // objectUUID: '0a507db0-ab20-48cb-913a-d2cb71ee0527',
  // objectUUID: 'ade46ed0-ad83-4a4d-8e82-053b7544a08e',
  objectUUID: '',
  mapCenter: { lat: 55.751768, lng: 37.61714 },
  status: 'no plans showed',
  plans: [],
  map: null,
  azimuth: 0,
  showPlans: false,
  zoom: 14,
  showWarningModal: false
});

export default Context;
