import axios from 'axios';

export const getData = async (token, url, name, dispatch) => {
  const headers = {
    'x-token': token
  };
  try {
    const response = await axios.get(url, { headers });
    dispatch({
      type: name,
      payload: {
        json: await response.data
      }
    });
  } catch (error) {
    handleError(error, dispatch);
    return {
      success: false,
      error: error
    };
  }
};

export const postData = async (token, url, data, dispatch) => {
  const headers = {
    'x-token': token
  };
  try {
    const response = await axios({ method: 'post', url, headers, data });
    return {
      success: true,
      response: response.data
    };
  } catch (error) {
    handleError(error, dispatch);
    return {
      success: false,
      error: error
    };
  }
};

export const putData = async (token, url, data, dispatch) => {
  const headers = {
    'x-token': token
  };
  try {
    const response = await axios({ method: 'put', url, headers, data });
    return {
      success: true,
      response: response.data
    };
  } catch (error) {
    handleError(error, dispatch);
    return {
      success: false,
      error: error
    };
  }
};

export const deleteData = async (token, url, dispatch) => {
  const headers = {
    'x-token': token
  };
  try {
    const response = await axios({ method: 'delete', url, headers });
    return {
      success: true,
      response: response.data
    };
  } catch (error) {
    handleError(error, dispatch);
    return {
      success: false,
      error: error
    };
  }
};

const handleError = (error, dispatch) => {
  dispatch({
    type: 'toggleOverlayModal',
    payload: {
      show: true,
      options: {
        type: 'server_error',
        title: 'Error',
        error
      }
    }
  });
};
