import React, { useRef, useEffect, useContext, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import L from 'leaflet';
import { Pane, LayerGroup, Tooltip, Polygon } from 'react-leaflet';
import context from '../../context';

import '../../leaflet/leaflet.css';
import '../../style/tooltip.css';

const PaneRegionGroups = React.memo(props => {
  const { state, dispatch } = useContext(context);
  const { regionGroups, layers, allowMapClick, polygonToCreate } = state;

  const onPolygonClick = (layerType, group, region) => e => {
    if (!allowMapClick || polygonToCreate) return;
    if (!layers.filter(item => item.type === layerType)[0].active || !group.active) return;

    dispatch({
      type: 'onCreatePolygon',
      payload: {
        mode: 'edit',
        region_id: region.id,
        layerType: 'region_groups',
        region_group_id: group.id,
        region_name: region.name,
        population_name: region.population_name,
        population_number: region.population_number,
        region_geometry: region.geometry
      }
    });
  };

  const metresPerPixel =
    (40075016.686 *
      Math.abs(Math.cos((props.mapRef.current.leafletElement.getCenter().lat / 180) * Math.PI))) /
    Math.pow(2, props.currentZoom + 8);

  const showRegionGroupsPane = useMemo(() => {
    return layers.filter(item => item.type === 'region_groups')[0].visible;
  }, [layers]);

  const polygons = group => {
    let zIndex = group.active ? 300 : 200;

    return (
      <Pane style={{ zIndex }} key={`RegionsLayerGroup - ${group.name}`}>
        {group.regions.map((region, i) => {
          const positions =
            region.geometry.type === 'Polygon'
              ? region.geometry.coordinates[0].map(point => L.latLng(point[1], point[0]))
              : region.geometry.coordinates.map(item => {
                  // return item[0].map(point => L.latLng(point[1], point[0]));
                  return item.map(pointArr => pointArr.map(point => L.latLng(point[1], point[0])));
                });
          const opacity = polygonToCreate ? 0.2 : 0.5;
          const strokeOpacity = polygonToCreate ? 0.2 : 1;

          return (
            <Polygon
              pmIgnore={true}
              key={`${region.name}-${i}`}
              positions={positions}
              fillColor={group.color}
              fillOpacity={opacity}
              color={group.color}
              opacity={strokeOpacity}
              weight={2}
              onClick={onPolygonClick('region_groups', group, region)}
            >
              {group.showNames && (
                <Tooltip className={'toolipClass'} permanent={true} direction={'center'}>
                  {region.name}
                </Tooltip>
              )}
            </Polygon>
          );
        })}
      </Pane>
    );
  };

  const panes = () => {
    return regionGroups
      .filter(group => group.visible)
      .map(group => {
        return polygons(group);
      });
  };

  return showRegionGroupsPane ? <Pane style={{ zIndex: props.zIndex }}>{panes()}</Pane> : null;
});

export default PaneRegionGroups;
