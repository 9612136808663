import React, { useEffect, useRef, useContext, useMemo, useState } from 'react';
import { Map, TileLayer, LayersControl } from 'react-leaflet';
import ReactDistortableImageOverlay from '../react-leaflet-distortable-imageoverlay/dist/index';
import 'leaflet/dist/leaflet.css';
import Context from '../context';
import { GlobalContext } from '../../../App';
import { useHotkeys } from 'react-hotkeys-hook';

import 'react-rangeslider/lib/index.css';
import '../styles/index.css';
import { point } from 'leaflet';

export default function LeafletMap() {
    const mapRef = useRef(null);
    const { state, dispatch } = useContext(Context);
    const { maps } = useContext(GlobalContext);
    const [direction, set_direction] = useState({});

    const arrowPress = (direction) => {
        set_direction({ direction });
    };

    const pointToLatLng = (arr) => arr.map((point) => mapRef.current.leafletElement.layerPointToLatLng(point));

    const updateCorners = (plan, newCorners) => {
        dispatch({
            type: 'UPDATE_CORNERS',
            payload: {
                corners: newCorners,
                uuid: plan.uuid,
                floor: plan.floor,
            },
        });
    };

    useEffect(() => {
        const [plan] = state.plans.filter((item) => item.editMode === 'translate');

        if (plan) {
            const pixelCorners = plan.corners.map((point) => mapRef.current.leafletElement.latLngToLayerPoint(point));
            switch (direction.direction) {
                case 'up':
                    updateCorners(plan, pointToLatLng(pixelCorners.map((point) => ({ ...point, y: point.y - 1 }))));
                    break;
                case 'down':
                    updateCorners(plan, pointToLatLng(pixelCorners.map((point) => ({ ...point, y: point.y + 1 }))));
                    break;
                case 'left':
                    updateCorners(plan, pointToLatLng(pixelCorners.map((point) => ({ ...point, x: point.x - 1 }))));
                    break;
                case 'right':
                    updateCorners(plan, pointToLatLng(pixelCorners.map((point) => ({ ...point, x: point.x + 1 }))));
                    break;

                default:
                    break;
            }
        }
    }, [direction]);

    useHotkeys('left', () => arrowPress('left'));
    useHotkeys('right', () => arrowPress('right'));
    useHotkeys('up', () => arrowPress('up'));
    useHotkeys('down', () => arrowPress('down'));

    if (state.mapCenter === {}) return null;

    useEffect(() => {
        updateMapPixelSize();
        window.addEventListener('resize', updateMapPixelSize);
        return () => {
            window.removeEventListener('resize', updateMapPixelSize);
        };
    }, [state.mapCenter]);

    const updateMapPixelSize = () => {
        dispatch({
            type: 'SET_MAP',
            payload: mapRef,
        });
    };

    const onCornersUpdated = (corners) => {
        // console.log(corners);
    };

    const onZoom = () => {
        dispatch({
            type: 'ZOOM_CHANGE',
            payload: mapRef.current.leafletElement._zoom,
        });
    };

    const renderMaps = useMemo(
        () =>
            maps.map((item, i) => {
                const checked = item.baseLayer.checked ? item.baseLayer.checked : false;
                return (
                    <LayersControl.BaseLayer name={item.baseLayer.name} checked={checked} key={`LayersControl.BaseLayer-${i}`}>
                        <TileLayer attributionPrefix={false} minZoom={0} maxZoom={20} {...item.tileLayer} />
                    </LayersControl.BaseLayer>
                );
            }),
        [maps]
    );

    return maps.length !== 0 ? (
        <Map
            keyboard={false}
            ref={mapRef}
            center={state.mapCenter}
            zoom={state.zoom}
            onZoom={onZoom}
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <LayersControl position="topright">
                {renderMaps}
                {/* <LayersControl.BaseLayer name="Google" checked={true}>
                    <TileLayer
                        attribution="google"
                        minZoom={0}
                        maxZoom={20}
                        url="http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="ArcGIS">
                    <TileLayer
                        attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                        minZoom={0}
                        maxZoom={20}
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="OSM">
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        attributionPrefix={false}
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer> */}
            </LayersControl>

            {state.plans && state.showPlans
                ? state.plans
                      .filter((plan) => plan.isAnchor)
                      .map((plan) => {
                          if (!plan.htmlImage || plan.corners.indexOf(undefined) !== -1) {
                              return null;
                          }
                          return (
                              <ReactDistortableImageOverlay
                                  key={plan.uuid}
                                  url={plan.htmlImage}
                                  editMode={plan.editMode}
                                  onCornersUpdated={onCornersUpdated}
                                  onWellKnownTextUpdated={(corners) => {
                                      console.log(corners);

                                      dispatch({
                                          type: 'UPDATE_CORNERS',
                                          payload: {
                                              corners,
                                              uuid: plan.uuid,
                                              floor: plan.floor,
                                          },
                                      });
                                  }}
                                  corners={plan.corners}
                                  cornersForeign={plan.corners}
                                  opacity={plan.opacity}
                                  color={plan.color}
                                  anchor={plan.isAnchor}
                              />
                          );
                      })
                : null}
            {state.plans && state.showPlans
                ? state.plans
                      .filter((plan) => !plan.isAnchor)
                      .map((plan) => {
                          if (!plan.htmlImage || plan.corners.indexOf(undefined) !== -1) return null;

                          return (
                              <ReactDistortableImageOverlay
                                  key={plan.uuid}
                                  url={plan.htmlImage}
                                  editMode={plan.editMode}
                                  onCornersUpdated={onCornersUpdated}
                                  onWellKnownTextUpdated={(corners) =>
                                      dispatch({
                                          type: 'UPDATE_CORNERS',
                                          payload: {
                                              corners,
                                              uuid: plan.uuid,
                                              floor: plan.floor,
                                          },
                                      })
                                  }
                                  corners={plan.corners}
                                  cornersForeign={plan.corners}
                                  opacity={plan.opacity}
                                  color={plan.color}
                                  anchor={plan.isAnchor}
                              />
                          );
                      })
                : null}
        </Map>
    ) : null;
}
