// import DATA from '../components/maps/outdoor/data.json';
import { generatePolygon, arrayToCSV } from './tools';

const addSelected = data => {
    return data.map(item => ({
        ...item,
        outdoor_details: { ...item.outdoor_details, selected: false }
    }));
};

const handleInputChange = (state, action) => {
    const { point, typeOfRouter, field, value, checked } = action.payload;
    const { innerData, allowEditCatalog } = state;
    const data = innerData[typeOfRouter].map(item => {
        return item.id !== point.id
            ? item
            : { ...item, [field]: allowEditCatalog[typeOfRouter][field].type !== 'checkbox' ? value : checked };
    });
    return { ...state, innerData: { ...state.innerData, [typeOfRouter]: [...data] }, saveStatus: 'none' };
};

const handleAddNewInputChange = (state, action) => {
    const { point, typeOfRouter, field, value, checked } = action.payload;
    const { addNew, allowEditCatalog } = state;
    addNew[field] = allowEditCatalog[typeOfRouter][field].type !== 'checkbox' ? value : checked;

    return { ...state, addNew };
};

const handleSelectChange = (state, action) => {
    const { point, typeOfRouter, field, value } = action.payload;
    const { innerData, spacesTypesCatalog } = state;
    const option = spacesTypesCatalog.filter(item => {
        return item.id === Number(value);
    })[0];
    const data = innerData[typeOfRouter].map(item => {
        return item.id !== point.id ? item : { ...item, [field]: option };
    });
    return { ...state, innerData: { ...state.innerData, [typeOfRouter]: [...data] }, saveStatus: 'none' };
};

const handleAddNewSelectChange = (state, action) => {
    const { point, typeOfRouter, field, value } = action.payload;
    const { addNew, spacesTypesCatalog } = state;
    const option = spacesTypesCatalog.filter(item => {
        return item.id === Number(value);
    })[0];
    addNew[field] = { ...option };
    return { ...state, addNew };
};

const handleZoomChange = (state, action) => {
    const currentZoom = action.payload.currentZoom;
    if (currentZoom >= state.thresholdZoomLevel) {
        return { ...state, renderMode: 'routers', currentZoom };
    } else {
        return { ...state, renderMode: 'markers', currentZoom };
    }
};

const aimOnPoint = (state, action) => {
    const mapCenter = action.center;
    const initialZoom = 15;
    return { ...state, mapCenter, initialZoom };
};

const handlePickCursorCoords = (state, action) => {
    const { isPickCursorCoords } = state;
    return { ...state, isPickCursorCoords: !isPickCursorCoords };
};

const setInitialZoom = (state, action) => {
    const initialZoom = action.payload.currentZoom;
    return { ...state, initialZoom };
};

const handleSaveCenter = (state, action) => {
    const mapCenter = action.payload.center;
    return { ...state, mapCenter };
};

const getOutdoorSpaces = (state, action) => {
    const outdoorSpacesData = action.payload.json;
    const allCenters = new Set([...state.allCenters, ...outdoorSpacesData.map(item => item.latitude + '_' + item.longitude)]);
    const innerData = { ...state.innerData, adSpaces: outdoorSpacesData };
    return { ...state, outdoorSpacesData, innerData, allCenters };
};

const getIPoints = (state, action) => {
    const outdoorIPointsData = action.payload.json;
    const allCenters = new Set([...state.allCenters, ...outdoorIPointsData.map(item => item.latitude + '_' + item.longitude)]);
    const innerData = { ...state.innerData, iPoints: outdoorIPointsData };
    return { ...state, outdoorIPointsData, innerData, allCenters };
};

const getProjectLocations = (state, action) => {
    const projectLocationsData = {};
    action.payload.json.forEach(item => (projectLocationsData[item.id] = item));
    return { ...state, projectLocationsData };
};

const handlePatchSucсess = (state, action) => {
    return { ...state, saveStatus: 'sucсess', isPickCursorCoords: false };
};

const handlePatchError = (state, action) => {
    return { ...state, saveStatus: 'error', isPickCursorCoords: false };
};

const getSpacesTypes = (state, action) => {
    const spacesTypesCatalog = action.payload.json;
    return { ...state, spacesTypesCatalog };
};

const handleCloseContextMenu = (state, action) => {
    return { ...state, contextMenu: {} };
};

const setCursorCoords = (state, action) => {
    const { cursorCoords } = action.payload;
    return { ...state, cursorCoords };
};

const setToken = (state, action) => {
    const token = action.payload.token;
    return { ...state, token };
};

const handleCloseEditModal = (state, action) => {
    const { outdoorSpacesData, outdoorIPointsData, innerData } = state;
    const data = { ...innerData, adSpaces: outdoorSpacesData, iPoints: outdoorIPointsData };
    return {
        ...state,
        editModal: {},
        contextMenu: {},
        addNew: {},
        innerData: data,
        saveStatus: 'none',
        isPickCursorCoords: false
    };
};

const handleEditRouterClick = (state, action) => {
    const { data } = action.payload;
    return { ...state, editModal: { ...state.editModal, show: true, id: data.routerInfo.id, typeOfRouter: data.typeOfRouter } };
};

const onAddButtonClick = (state, action) => {
    const { selectedPlId, typeOfRouter } = action.payload;
    const { projectLocationsData, spacesTypesCatalog, allowEditCatalog } = state;
    const addNew = {};
    Object.keys(allowEditCatalog[typeOfRouter]).forEach(key => {
        addNew[key] = allowEditCatalog[typeOfRouter][key].defaultValue;
    });
    addNew.latitude = projectLocationsData[selectedPlId].latitude;
    addNew.longitude = projectLocationsData[selectedPlId].longitude;
    return {
        ...state,
        addNew,
        editModal: {
            ...state.editModal,
            show: true,
            selectedPlId,
            selectedPlName: projectLocationsData[selectedPlId].name,
            typeOfRouter
        }
    };
};

const handleMapClick = (state, action) => {
    // console.log(state, action);
    const { editModal, innerData, addNew } = state;
    const { cursorCoords } = action.payload;
    if (Object.keys(addNew).length !== 0) {
        return { ...state, addNew: { ...addNew, latitude: cursorCoords.lat, longitude: cursorCoords.lng } };
    } else {
        const modifiedInnerData = innerData[editModal.typeOfRouter].map(item => {
            if (item.id !== editModal.id) {
                return item;
            } else {
                return { ...item, latitude: cursorCoords.lat, longitude: cursorCoords.lng };
            }
        });
        return { ...state, innerData: { ...innerData, [editModal.typeOfRouter]: modifiedInnerData } };
    }
};

const handleRightSectorClick = (state, action) => {
    const { sectorId, typeOfRouter, cursorCoords } = action.payload;
    const routerInfo = state.innerData[typeOfRouter].filter(item => item.id === sectorId)[0];
    const contextMenu = {
        show: true,
        x: cursorCoords.x,
        y: cursorCoords.y,
        routerInfo,
        typeOfRouter
    };
    return { ...state, contextMenu };
};

const onSectorClick = (state, action) => {
    const { spaceId } = action.payload;
    const { selectedSpaces } = state;

    if (selectedSpaces.indexOf(spaceId) === -1) {
        selectedSpaces.push(spaceId);
    } else {
        selectedSpaces.splice(selectedSpaces.indexOf(spaceId), 1);
    }
    return { ...state, selectedSpaces: [...selectedSpaces] };
};

const selectPL = (state, action) => {
    const selectedPL = action.id === state.selectedPL[0] ? [] : [action.id];
    return { ...state, selectedPL };
};

const setQuery = (state, action) => {
    const { pl_id } = action;
    window.history.pushState({}, '', `?pl_id=${pl_id}`);
    return { ...state };
};

const handleSearch = (state, action) => {
    return { ...state, searchValue: action.payload };
};

const togglePolygonsCatalogModal = (state, action) => {
    return { ...state, polygonsCatalogModal: { ...state.polygonsCatalogModal, show: !state.polygonsCatalogModal.show } };
};

const onTestClick = (state, action) => {
    return { ...state, test: action.payload };
};

const onLayerClick = (state, action) => {
    return { ...state, activeLayerId: action.payload.id };
};

const writePolygonsCatalog = (state, action) => {
    const { mapRef, innerData } = action.payload;
    const { polygonsCatalogModal } = state;
    polygonsCatalogModal.polygons = {};
    Object.keys(innerData).forEach(typeOfRouter => {
        polygonsCatalogModal.polygons[typeOfRouter] = {};
        const arr = innerData[typeOfRouter].map(item => {
            return generatePolygon(mapRef, item, typeOfRouter);
        });
        const csvArr = arr.map(item => {
            const wkt = item.polygon.reduce((acc, coords) => {
                return `${acc}${acc === '' ? '' : ','}(${coords[1]},${coords[0]})`;
            }, '');
            return {
                WKT: `"POLYGON(${wkt})"`,
                NAME: item.name,
                AZIMUT: item.orientation_azimuth,
                ID: item.id
            };
        });
        const jsonArr = arr.map(item => {
            return {
                WKT: item.polygon.map(item => item.reverse()),
                NAME: item.name,
                AZIMUT: item.orientation_azimuth,
                ID: item.id
            };
        });
        polygonsCatalogModal.polygons[typeOfRouter].CSV = arrayToCSV(csvArr);
        polygonsCatalogModal.polygons[typeOfRouter].JSON = JSON.stringify(jsonArr);
    });
    return { ...state, polygonsCatalogModal };
};

const OutdoorSpaces = (state, action) => {
    switch (action.type) {
        case 'getOutdoorSpaces':
            return getOutdoorSpaces(state, action);
        case 'getSpacesTypes':
            return getSpacesTypes(state, action);
        case 'getIPoints':
            return getIPoints(state, action);
        case 'getProjectLocations':
            return getProjectLocations(state, action);
        case 'handleRightSectorClick':
            return handleRightSectorClick(state, action);
        case 'onSectorClick':
            return onSectorClick(state, action);
        case 'aimOnPoint':
            return aimOnPoint(state, action);
        case 'handleZoomChange':
            return handleZoomChange(state, action);
        case 'handleSaveCenter':
            return handleSaveCenter(state, action);
        case 'setInitialZoom':
            return setInitialZoom(state, action);
        case 'handleCloseContextMenu':
            return handleCloseContextMenu(state, action);
        case 'handleEditRouterClick':
            return handleEditRouterClick(state, action);
        case 'handleCloseEditModal':
            return handleCloseEditModal(state, action);
        case 'handleInputChange':
            return handleInputChange(state, action);
        case 'handleSelectChange':
            return handleSelectChange(state, action);
        case 'handleAddNewSelectChange':
            return handleAddNewSelectChange(state, action);
        case 'setToken':
            return setToken(state, action);
        case 'handlePatchError':
            return handlePatchError(state, action);
        case 'handlePatchSucсess':
            return handlePatchSucсess(state, action);
        case 'handlePickCursorCoords':
            return handlePickCursorCoords(state, action);
        case 'handleMapClick':
            return handleMapClick(state, action);
        case 'setCursorCoords':
            return setCursorCoords(state, action);
        case 'selectPL':
            return selectPL(state, action);
        case 'setQuery':
            return setQuery(state, action);
        case 'onAddButtonClick':
            return onAddButtonClick(state, action);
        case 'handleAddNewInputChange':
            return handleAddNewInputChange(state, action);
        case 'handleSearch':
            return handleSearch(state, action);
        case 'writePolygonsCatalog':
            return writePolygonsCatalog(state, action);
        case 'togglePolygonsCatalogModal':
            return togglePolygonsCatalogModal(state, action);
        case 'onTestClick':
            return onTestClick(state, action);
        case 'onLayerClick':
            return onLayerClick(state, action);
        default:
            return state;
    }
};

export default OutdoorSpaces;
