import cloneDeep from 'lodash-es/cloneDeep';

const getProjectLocations = (state, action) => {
  const { projectLocationsByPlType, colors } = state;
  action.payload.json.forEach((item, i) => {
    if (projectLocationsByPlType[item.pl_type]) {
      projectLocationsByPlType[item.pl_type].data[item.id] = item;
      projectLocationsByPlType[item.pl_type].number++;
    } else {
      projectLocationsByPlType[item.pl_type] = {
        visible: false,
        active: false,
        number: 0,
        data: {}
      };
      projectLocationsByPlType[item.pl_type].data[item.id] = item;
      projectLocationsByPlType[item.pl_type].number++;
    }
  });

  Object.keys(projectLocationsByPlType).forEach((key, i) => {
    const color = colors[i] ? colors[i] : colors[Math.floor(Math.random() * (colors.length - 1))];
    if (key !== 'All') {
      projectLocationsByPlType[key].color = color;
    }
  });

  projectLocationsByPlType['All'].number = Object.keys(projectLocationsByPlType).reduce(
    (acc, key) => {
      return key !== 'All' ? (acc += Number(projectLocationsByPlType[key].number)) : acc;
    },
    0
  );

  return { ...state, projectLocationsByPlType };
};

const getRegionGroups = (state, action) => {
  const { colors } = state;
  const regionGroups = action.payload.json.map((group, i) => {
    const color = colors[i] ? colors[i] : colors[Math.floor(Math.random() * (colors.length - 1))];
    const regions = group.regions.map(region => {
      return { ...region, active: false, changed: false };
    });
    return { ...group, regions, color, visible: false, active: false, showNames: false };
  });

  return { ...state, regionGroups };
};

const getMalls = (state, action) => {
  const malls = action.payload.json;
  return { ...state, malls };
};

const onLayerClick = (state, action) => {
  const { id } = action.payload;

  const layers = state.layers.map(item => {
    if (item.id !== id) {
      return { ...item, active: false };
    } else {
      return { ...item, active: true, visible: true };
    }
  });

  return { ...state, layers };
};

const onLayerEyeClick = (state, action) => {
  const { id } = action.payload;
  const layers = [...state.layers].map(item => {
    if (item.id !== id) {
      return item;
    } else {
      const visible = !item.visible;
      return { ...item, visible };
    }
  });

  return { ...state, layers };
};

const onPlDetailsEyeClick = (state, action) => {
  const { plType } = action.payload;
  const { projectLocationsByPlType } = state;
  if (plType !== 'All') {
    projectLocationsByPlType[plType].visible = !projectLocationsByPlType[plType].visible;
    if (!projectLocationsByPlType[plType].visible) {
      projectLocationsByPlType['All'].visible = false;
    }
  } else {
    if (projectLocationsByPlType['All'].visible === false) {
      Object.keys(projectLocationsByPlType).forEach(key => {
        projectLocationsByPlType[key].visible = true;
      });
    } else if (projectLocationsByPlType['All'].visible === true) {
      Object.keys(projectLocationsByPlType).forEach(key => {
        projectLocationsByPlType[key].visible = false;
      });
    }
  }
  return { ...state, projectLocationsByPlType };
};

const onRegionGroupEyeClick = (state, action) => {
  const { groupId } = action.payload;
  const regionGroups = state.regionGroups.map(group => {
    if (group.id === groupId) {
      const visible = !group.visible;
      return { ...group, visible };
    } else return group;
  });

  return { ...state, regionGroups };
};

const onRegionGroupClick = (state, action) => {
  const { groupId, layerType } = action.payload;
  const regionGroups = state.regionGroups.map(group => {
    if (group.id === groupId) {
      return { ...group, visible: true, active: true };
    } else {
      return { ...group, active: false };
    }
  });

  return { ...state, regionGroups, groupToEdit: { groupId, layerType } };
};

const onShowNameClick = (state, action) => {
  const { groupId } = action.payload;
  const regionGroups = state.regionGroups.map(group => {
    if (group.id === groupId) {
      const showNames = !group.showNames;
      return { ...group, showNames };
    } else {
      return group;
    }
  });

  return { ...state, regionGroups };
};

const onCreatePolygon = (state, action) => {
  const {
    mode,
    region_id,
    layerType,
    region_group_id,
    region_name,
    population_name,
    population_number,
    region_geometry
  } = action.payload;
  return {
    ...state,
    polygonToCreate: {
      mode,
      region_id,
      layerType,
      region_group_id,
      region_name,
      population_name,
      population_number,
      region_geometry
    }
  };
};

const handleZoomChange = (state, action) => {
  const currentZoom = action.payload.currentZoom;
  return { ...state, currentZoom };
};

const resetGroupToEdit = (state, action) => {
  return { ...state, groupToEdit: undefined };
};

const toggleAllowMapClick = (state, action) => {
  return { ...state, allowMapClick: action.payload };
};

const saveEditedPolygonToBuffer = (state, action) => {
  const { id, groupId, layerType, newCoordinates, coordinatesIndex } = action.payload;

  let regionGroups;
  switch (layerType) {
    case 'region_groups':
      regionGroups = cloneDeep(state.regionGroups).map(group => {
        if (group.id === groupId) {
          const regions = group.regions.map(region => {
            if (region.id !== id) {
              return region;
            } else {
              const coordinates = region.geometry.coordinates.map((coord, i) => {
                if (i === coordinatesIndex) {
                  return coord.map((arr, j) => {
                    if (j === 0) {
                      return newCoordinates;
                    } else {
                      return arr;
                    }
                  });
                } else {
                  return coord;
                }
              });
              const geometry = { ...region.geometry, coordinates };
              return { ...region, geometry, changed: true };
            }
          });
          return { ...group, regions };
        } else {
          return group;
        }
      });

      return { ...state, regionGroups };

    default:
      return { ...state };
  }
};

const toggleOverlayModal = (state, action) => {
  const { show, options } = action.payload;
  const overlayModal = { show, options };
  return { ...state, overlayModal };
};

const changeNewPolygonGeometry = (state, action) => {
  const { region_geometry } = action.payload;
  return { ...state, polygonToCreate: { ...state.polygonToCreate, region_geometry } };
};

const changeNewPolygonParams = (state, action) => {
  const { field, value } = action.payload;
  return { ...state, polygonToCreate: { ...state.polygonToCreate, [field]: value } };
};

const resetPolygonToCreate = (state, action) => {
  return { ...state, polygonToCreate: undefined };
};

const handleChangePlRadius = (state, action) => {
  return { ...state, changePlRadius: action.payload };
};

const handleChangePlOpacity = (state, action) => {
  return { ...state, plOpacity: action.payload };
};

const toggleShowMallsNames = (state, action) => {
  return { ...state, showMallsNames: action.payload };
};

const handleBoundsChange = (state, action) => {
  return { ...state, currentBounds: action.payload.currentBounds };
};

const OutdoorMapService = (state, action) => {
  switch (action.type) {
    case 'getProjectLocations':
      return getProjectLocations(state, action);
    case 'getRegionGroups':
      return getRegionGroups(state, action);
    case 'getMalls':
      return getMalls(state, action);
    case 'onLayerClick':
      return onLayerClick(state, action);
    case 'onLayerEyeClick':
      return onLayerEyeClick(state, action);
    case 'onPlDetailsEyeClick':
      return onPlDetailsEyeClick(state, action);
    case 'onRegionGroupEyeClick':
      return onRegionGroupEyeClick(state, action);
    case 'onRegionGroupClick':
      return onRegionGroupClick(state, action);
    case 'onShowNameClick':
      return onShowNameClick(state, action);
    case 'onCreatePolygon':
      return onCreatePolygon(state, action);
    case 'handleZoomChange':
      return handleZoomChange(state, action);
    case 'toggleAllowMapClick':
      return toggleAllowMapClick(state, action);
    case 'saveEditedPolygonToBuffer':
      return saveEditedPolygonToBuffer(state, action);
    case 'toggleOverlayModal':
      return toggleOverlayModal(state, action);
    case 'resetGroupToEdit':
      return resetGroupToEdit(state, action);
    case 'changeNewPolygonGeometry':
      return changeNewPolygonGeometry(state, action);
    case 'changeNewPolygonParams':
      return changeNewPolygonParams(state, action);
    case 'resetPolygonToCreate':
      return resetPolygonToCreate(state, action);
    case 'handleChangePlRadius':
      return handleChangePlRadius(state, action);
    case 'handleChangePlOpacity':
      return handleChangePlOpacity(state, action);
    case 'toggleShowMallsNames':
      return toggleShowMallsNames(state, action);
    case 'handleBoundsChange':
      return handleBoundsChange(state, action);
    default:
      return state;
  }
};

export default OutdoorMapService;
