import L from 'leaflet';
import {
    generateColorForObjects,
    calculateAzimuth,
    calculateCorners,
    toRadians,
    toDegrees,
    convertFromCoordPointToLatLng,
    _rotateBy,
    calculateMidPoint,
} from '../tools';

export default function reducer(state, action) {
    const { map } = state;

    switch (action.type) {
        case 'SAVE_PLANS':
            return { ...state, status: action.payload };

        case 'UUID_CHANGE':
            return { ...state, objectUUID: action.payload };

        case 'CENTER_LAT_CHANGE':
            return { ...state, mapCenter: { ...state.mapCenter, lat: action.payload } };

        case 'CENTER_LNG_CHANGE':
            return { ...state, mapCenter: { ...state.mapCenter, lng: action.payload } };

        case 'SET_MAP':
            return {
                ...state,
                status: 'save plans',
                map: action.payload,
            };

        case 'ZOOM_CHANGE':
            return {
                ...state,
                status: 'save plans',
                zoom: action.payload,
            };

        case 'SHOW_WARNING_MODAL':
            return {
                ...state,
                showWarningModal: { show: true, planToBeAnchor: action.payload },
            };

        case 'CLOSE_WARNING_MODAL':
            return {
                ...state,
                showWarningModal: { show: false, planToBeAnchor: null },
            };

        case 'OPACITY_CHANGE':
            return {
                ...state,
                plans: state.plans.map((item) => {
                    return item.uuid === action.payload.uuid ? { ...item, opacity: action.payload.value / 100 } : { ...item };
                }),
            };

        case 'MODE_CHANGE':
            const newPlans = state.plans.map((item) => {
                return item.uuid === action.payload.uuid ? { ...item, editMode: action.payload.value } : { ...item, editMode: 'lock' };
            });
            return {
                ...state,
                plans: newPlans,
            };

        case 'ANCHOR_CHANGE':
            // map.current.leafletElement.off();
            // map.current.leafletElement.remove();

            window.location.reload();
            // const elem = document.querySelector('.leaflet-pane .leaflet-marker-pane');
            // while (elem.firstChild) {
            //   elem.removeChild(elem.firstChild);
            // }

            // const paths = document.querySelectorAll('.leaflet-path-draggable .leaflet-interactive');
            // console.log(paths);

            // const newAnchorPlans = state.plans.map(item => {
            //   return item.uuid === action.payload.uuid ? { ...item, isAnchor: true } : { ...item, isAnchor: false };
            // });
            return {
                ...state,
                // plans: newAnchorPlans
            };

        case 'UPDATE_CORNERS':
            const prevAzimuth = state.azimuth;
            const newAzimuth = calculateAzimuth(action.payload.corners);
            const crs = map.current.leafletElement.options.crs;
            const updatedPlans = state.plans.map((item) => {
                const prevCorners = item.corners;
                const delta = toRadians(newAzimuth - prevAzimuth);
                const otherCorners = _rotateBy(prevCorners, delta, crs, map.current.leafletElement._zoom);
                return item.uuid === action.payload.uuid
                    ? { ...item, corners: action.payload.corners, center: calculateMidPoint(action.payload.corners) }
                    : { ...item, corners: otherCorners, center: calculateMidPoint(otherCorners) };
            });

            return {
                ...state,
                azimuth: newAzimuth,
                status: 'save plans',
                plans: updatedPlans,
            };

        case 'GET_DATA':
            let mapCenter, azimuth, plans, zoom, objectUUID;

            try {
                mapCenter = {
                    lat: action.payload.data.plans[0].layers.geo_mapping_layer.plan_center[0],
                    lng: action.payload.data.plans[0].layers.geo_mapping_layer.plan_center[1],
                };
            } catch (error) {
                mapCenter =
                    Number(action.payload.query.latitude) && Number(action.payload.query.longitude)
                        ? {
                              lat: Number(action.payload.query.latitude),
                              lng: Number(action.payload.query.longitude),
                          }
                        : state.mapCenter;
            }

            try {
                azimuth = action.payload.data.plans[0].layers.geo_mapping_layer.azimuth;
                if (!azimuth) {
                    azimuth = state.azimuth;
                }
            } catch (error) {
                azimuth = state.azimuth;
            }

            if (action.payload.data.plans && action.payload.data.plans[0].layers.geo_mapping_layer.zoom_level) {
                zoom = action.payload.data.plans[0].layers.geo_mapping_layer.zoom_level;
            } else if (action.payload.data.plans && action.payload.data.plans.length !== 0) {
                zoom = 18;
            } else {
                zoom = state.zoom;
            }

            try {
                plans = action.payload.data.plans.map((item, i) => {
                    const {
                        plan_top_left,
                        plan_top_right,
                        plan_bottom_left,
                        plan_bottom_right,
                        is_anchor,
                        plan_center,
                    } = item.layers.geo_mapping_layer;
                    const isAnchor = is_anchor !== undefined ? is_anchor : is_anchor === undefined && i === 0 ? true : false;
                    const src = `https://maps.getshopster.net${item.image}`;
                    const htmlImage = document.createElement('img');
                    htmlImage.src = src;
                    // htmlImage.crossOrigin = 'use-credentials';
                    htmlImage.alt = 'plan';
                    const corners = [plan_top_left, plan_top_right, plan_bottom_left, plan_bottom_right].map((item) =>
                        convertFromCoordPointToLatLng(item)
                    );
                    const center = plan_center ? convertFromCoordPointToLatLng(plan_center) : undefined;
                    return {
                        ...item,
                        htmlImage,
                        center,
                        corners,
                        isAnchor,
                        opacity: 0.5,
                        color: generateColorForObjects(item.uuid),
                        editMode: 'lock',
                    };
                });
            } catch (error) {
                plans = [];
            }

            objectUUID = action.payload.query.plan_set_uuid ? action.payload.query.plan_set_uuid : state.objectUUID;

            return {
                ...state,
                plans,
                zoom,
                azimuth,
                mapCenter,
                status: 'save plans',
                data: action.payload.data,
                objectUUID,
            };

        case 'SHOW_PLANS':
            if (state.plans.length === 0) return state;
            // console.log(state.data.plans);
            // console.log(state.plans);
            // console.log(state.azimuth);

            try {
                const plans = state.plans.map((item, i) => {
                    const src = `https://maps.getshopster.net${item.image}`;
                    const htmlImage = document.createElement('img');
                    htmlImage.src = src;
                    // htmlImage.crossOrigin = 'use-credentials';
                    htmlImage.alt = 'plan';
                    const [sourcePlan] = state.data.plans.filter((plan) => plan.uuid === item.uuid);
                    let prevAzimuth = sourcePlan.layers.geo_mapping_layer.azimuth ? sourcePlan.layers.geo_mapping_layer.azimuth : 0;
                    let prev_scale = item.layers.geo_mapping_layer.prev_scale ? item.layers.geo_mapping_layer.prev_scale : item.scale;
                    let corners;
                    if (!item.corners[0] || !item.corners[1] || !item.corners[2] || !item.corners[3] || prev_scale !== item.scale) {
                        corners = calculateCorners(state.map.current.leafletElement, item);
                        prevAzimuth = 0;
                        prev_scale = item.scale;
                    } else {
                        corners = item.corners;
                    }

                    if (prevAzimuth !== state.azimuth) {
                        const crs = map.current.leafletElement.options.crs;
                        const prevCorners = corners;
                        const delta = toRadians(state.azimuth - prevAzimuth);
                        corners = _rotateBy(prevCorners, delta, crs, map.current.leafletElement._zoom);
                    }
                    const center = calculateMidPoint(corners);

                    return {
                        ...item,
                        htmlImage,
                        prev_scale,
                        center,
                        corners,
                        opacity: 0.5,
                        color: generateColorForObjects(item.uuid),
                        editMode: 'lock',
                    };
                });

                return {
                    ...state,
                    showPlans: action.payload,
                    status: 'save plans',
                    plans,
                };
            } catch (error) {
                return state;
            }

        default:
            return state;
    }
}
