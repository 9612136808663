import React, { useContext } from 'react';
import Slider from 'react-rangeslider';
import styled from 'styled-components/macro';
import Context from '../context';
import Modes from './modes';
import Anchor from './anchor';

const PlansContainer = styled.div``;

const PlansTitle = styled.h4`
    margin: 10px 0 10px 0;
    font-size: 18px !important;
    font-weight: 700 !important;
    padding: 0;
`;

const Div = styled.div`
    width: 100px;
    flex: 1;
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const Item = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin: 5px 0;
    border: 1px solid #777;
    border-radius: 5px;
`;

const Floor = styled.div`
    width: 40px;
    height: 40px;
    background-color: ${props => props.bgColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    margin-right: 10px;
`;

export default function Plans() {
    const { state, dispatch } = useContext(Context);

    const onOpacityChange = (uuid, value) => {
        dispatch({
            type: 'OPACITY_CHANGE',
            payload: { uuid, value }
        });
    };

    return state.data && state.plans ? (
        <PlansContainer>
            <PlansTitle>{state.data.name}</PlansTitle>
            <List>
                {state.plans
                    .sort((a, b) => b.floor - a.floor)
                    .map((plan, i) => {
                        return (
                            <Item key={plan.uuid}>
                                {state.plans.length > 1 && <Anchor plan={plan} />}
                                <Floor bgColor={plan.color}>{plan.floor}</Floor>
                                <Div>
                                    <Slider
                                        min={0}
                                        max={100}
                                        value={plan.opacity * 100.0}
                                        onChange={value => onOpacityChange(plan.uuid, value)}
                                    />
                                </Div>
                                <Modes plan={plan} />
                            </Item>
                        );
                    })}
            </List>
        </PlansContainer>
    ) : null;
}
