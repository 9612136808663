import React, { useContext, useMemo } from 'react';
import context from '../../context';
import styled from 'styled-components/macro';
import EyeIcon from '../eye_icon';
import ListItem from '../list_item';
import ListItemText from '../list_item_text';

const Layer = React.memo(({ layer, ...props }) => {
  const { state, dispatch } = useContext(context);

  const onLayerClick = id => e => {
    dispatch({
      type: 'onLayerClick',
      payload: { id }
    });
  };

  const onLayerEyeClick = id => e => {
    dispatch({
      type: 'onLayerEyeClick',
      payload: { id }
    });
  };

  const isActive = layer.active;
  const isVisible = layer.visible;

  return useMemo(
    () => (
      <ListItem isActive={isActive}>
        <ListItemText onClick={onLayerClick(layer.id)}>{layer.name}</ListItemText>
        <EyeIcon isVisible={isVisible} onClick={onLayerEyeClick(layer.id)} />
      </ListItem>
    ),
    [isActive, isVisible]
  );
});

export default Layer;
