import React, { useState, useContext } from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import context from '../context';
import { generatePolygon } from '../tools';

const Sectors = React.memo(({ data, map, typeOfRouter, isInner = false, mode = undefined }) => {
  const { state, dispatch } = useContext(context);
  if (!data) return null;

  const filteredData =
    state.selectedPL.length === 0 ? data : mode === 'addNew' ? [data] : data.filter(item => item.project_location === state.selectedPL[0]);

  const onRightClick = (id, typeOfRouter) => e => {
    const cursorCoords = {
      x: e.originalEvent.pageX,
      y: e.originalEvent.pageY
    };
    dispatch({
      type: 'handleRightSectorClick',
      payload: { sectorId: id, typeOfRouter, cursorCoords }
    });
  };

  const fillOpacity = isInner ? 1 : 0.5;

  const result = filteredData.map((item, i) => {
    const polygon = generatePolygon(map, item, typeOfRouter, isInner, mode);
    return (
      <Polygon
        key={`p-${item.id}`}
        sectorId={item.id}
        fillColor={polygon.polygonColor}
        fillOpacity={fillOpacity}
        // weight={1}
        color={'transparent'}
        positions={polygon.polygon}
        // onClick={onSectorClick(item.id, typeOfRouter)}
        onContextMenu={onRightClick(item.id, typeOfRouter)}
      >
        <Tooltip key={i}>{polygon.tooltip}</Tooltip>
      </Polygon>
    );
  });

  return result;
});

export default Sectors;
