import React, { useRef, useEffect, useContext, useMemo, useState } from 'react';
import EditControl from './EditControl';
import { FeatureGroup, Polygon, Tooltip, Pane } from 'react-leaflet';
import L from 'leaflet';
import context from '../context';

import '../style/tooltip.css';

const DrawControl = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const [editedPolyOptions, setEditedPolyOptions] = useState(null);
  // const [drawControl, setDrawControl] = useState(null);
  const { layers, regionGroups, polygonToCreate } = state;

  useEffect(() => {
    if (editedPolyOptions) {
      dispatch({
        type: 'saveEditedPolygonToBuffer',
        payload: editedPolyOptions
      });
    }
  }, [editedPolyOptions]);

  const _onEditStart = e => {
    dispatch({
      type: 'toggleAllowMapClick',
      payload: false
    });
  };
  const _onEditStop = e => {
    dispatch({
      type: 'toggleAllowMapClick',
      payload: true
    });
  };

  // const _onDeleteStart = e => {
  //   console.log(e);
  // };

  // const _onDeleteStop = e => {
  //   console.log(e);
  // };

  const _onDeleted = e => {
    const coordinates = Object.keys(e.sourceTarget._renderer._layers).map(key => {
      return e.sourceTarget._renderer._layers[key].toGeoJSON().geometry.coordinates;
    });
    modeDependentActions(coordinates, polygonToCreate.mode);
  };

  const modeDependentActions = (coordinates, mode) => {
    if (mode === 'edit') {
      const temp = { ...polygonToCreate, region_geometry: { type: 'MultiPolygon', coordinates } };
      dispatch({
        type: 'resetPolygonToCreate'
      });
      dispatch({
        type: 'onCreatePolygon',
        payload: temp
      });
    } else if (mode === 'create') {
      dispatch({
        type: 'changeNewPolygonGeometry',
        payload: { region_geometry: { type: 'MultiPolygon', coordinates } }
      });
    }
  };

  const _onCreated = e => {
    const region_geometry = polygonToCreate.region_geometry || {
      type: 'MultiPolygon',
      coordinates: []
    };
    const coordinates = [...region_geometry.coordinates, e.layer.toGeoJSON().geometry.coordinates];
    modeDependentActions(coordinates, polygonToCreate.mode);
  };

  const _onEditedNew = e => {
    const coordinates = Object.keys(e.sourceTarget._renderer._layers).map(key => {
      return e.sourceTarget._renderer._layers[key].toGeoJSON().geometry.coordinates;
    });
    modeDependentActions(coordinates, polygonToCreate.mode);
  };

  const polygons = () => {
    if (!polygonToCreate || polygonToCreate === null || polygonToCreate.mode === 'create') {
      return null;
    }
    let poly = [];
    if (polygonToCreate.layerType === 'region_groups') {
      const group = regionGroups.filter(item => item.id === polygonToCreate.region_group_id)[0];
      poly = polygonToCreate.region_geometry.coordinates.map((item, i) => {
        const positions = item.map(arr => {
          return arr.map(point => [point[1], point[0]]);
        });

        return {
          innerIndex: i,
          layerType: polygonToCreate.layerType,
          positions,
          color: group.color,
          name: item.region_name
        };
      });
    } else if (polygonToCreate.layerType === 'malls') {
      poly = polygonToCreate.region_geometry.coordinates.map((item, i) => {
        const positions = item.map(arr => {
          return arr.map(point => [point[1], point[0]]);
        });

        return {
          innerIndex: i,
          layerType: polygonToCreate.layerType,
          positions,
          color: '#f032e6',
          name: item.region_name
        };
      });
    }

    return poly.map((item, i) => {
      return (
        <Polygon
          key={`${item.name}-${i}-polygon`}
          positions={item.positions}
          color={item.color}
          attribution={String(i)}
        />
      );
    });
  };

  const getGeoJson = polygonToCreate => {
    return {
      type: 'FeatureCollection',
      features: polygonToCreate.region_geometry.coordinates.map(item => {
        return {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: item
          }
        };
      })
    };
  };

  let _editableFG = null;

  const _onFeatureGroupReady = reactFGref => {
    if (!reactFGref) return;
    let leafletGeoJSON = new L.GeoJSON(getGeoJson(polygonToCreate));
    let leafletFG = reactFGref.leafletElement;

    leafletGeoJSON.eachLayer(layer => {
      leafletFG.addLayer(layer);
    });
    _editableFG = reactFGref;
  };

  return useMemo(() => {
    return polygonToCreate ? (
      <Pane style={{ zIndex: 1000 }}>
        <FeatureGroup
          ref={reactFGref => {
            _onFeatureGroupReady(reactFGref);
          }}
        >
          <EditControl
            position="topright"
            // onEditStart={_onEditStart}
            // onEditStop={_onEditStop}
            onEdited={_onEditedNew}
            onCreated={_onCreated}
            // onDeleteStart={_onDeleteStart}
            // onDeleteStop={_onDeleteStop}
            onDeleted={_onDeleted}
            // onDrawStop={_onDrawStop}
            // onMounted={_onMounted}
            draw={{
              polygon: true,
              circle: false,
              marker: false,
              circlemarker: false,
              rectangle: false,
              polyline: false
            }}
            // edit={{
            //   remove: polygonToCreate.mode === 'edit' ? false : true
            // }}
          />
          {/* {polygons()} */}
        </FeatureGroup>
      </Pane>
    ) : null;
  }, [polygonToCreate]);
});

export default DrawControl;
