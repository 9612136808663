import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import cookie from 'react-cookies';
import styled from 'styled-components/macro';

import { parseQuery } from './components/tools';
import GeoMap from './components/geo-mapping';
import OutdoorSpaces from './components/outdoor-spaces';
import OutdoorMapService from './components/outdoor-map-service';
import NavMenu from './components/nav-menu';

const navHeight = '40px';

const Container = styled.div`
    display: grid;
    grid-template-rows: ${navHeight} 1fr;
`;

const Main = styled.main`
    height: ${() => `calc(100vh - ${navHeight})`};
`;

export const GlobalContext = React.createContext();

const App = React.memo(() => {
    const [token, setToken] = useState('');
    const [mainLocation, setMainLocation] = useState('');
    const [query, setQuery] = useState({});
    const [maps, setMaps] = useState([]);
    const [showMapService, setShowMapService] = useState(false);
    const [navList, setNavList] = useState(null);

    useEffect(() => {
      console.log('document.referrer', document.referrer);
      
        setMainLocation(document.referrer);
    }, []);

    useEffect(() => {
        const query = parseQuery(window.location.search);
        setQuery(prev => query);
        const myToken = query.token ? query.token : cookie.load('xtoken');

        if (!myToken || myToken === 'undefined') {
            window.location.replace('https://sso.wifizone.me/accounts/login?next=' + document.location.href, '_blank');
        }
        setToken(prev => myToken);
        if (token) {
            cookie.save('xtoken', token, { path: '/' });
            getMaps(token);
            getCurrent(token);
        }
    }, [token]);

    const getMaps = async token => {
        const url = `https://admin.client.getshopster.net/outdoor/map_options/`;
        const headers = {
            'x-token': token
        };
        const response = await axios.get(url, { headers });
        setMaps(prev => response.data);
    };

    const getCurrent = async token => {
        const url = `https://admin.client.getshopster.net/accounts/current`;
        const headers = {
            'x-token': token
        };
        const response = await axios.get(url, { headers });
        if (response.data.options.includes('role:superuser')) {
            setShowMapService(true);
            setNavList([
                { to: '/geo-mapping/', title: 'Geo Mapping' },
                { to: '/spaces/', title: 'Outdoor Spaces' },
                { to: '/outdoor-map-service/', title: 'Outdoor Map Service' }
            ]);
        } else {
            setNavList([
                { to: '/geo-mapping/', title: 'Geo Mapping' },
                { to: '/spaces/', title: 'Outdoor Spaces' }
            ]);
        }
    };

    return (
        <Router>
            {navList && (
                <GlobalContext.Provider value={{ token, maps }}>
                    <Container>
                        <NavMenu navList={navList} mainLocation={mainLocation} />
                        <Main>
                            <Route path="/geo-mapping/" render={() => <GeoMap />} />
                            <Route path="/spaces/" render={() => <OutdoorSpaces />} />
                            {showMapService && <Route path="/outdoor-map-service/" render={() => <OutdoorMapService />} />}
                        </Main>
                    </Container>
                </GlobalContext.Provider>
            )}
        </Router>
    );
});

export default App;
