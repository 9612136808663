import React, { useRef, useEffect, useContext, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import L from 'leaflet';
import { Pane, LayerGroup, Tooltip, Polygon } from 'react-leaflet';
import context from '../../context';

import '../../leaflet/leaflet.css';
import '../../style/tooltip.css';

const PaneRegionGroups = React.memo(props => {
  const { state, dispatch } = useContext(context);
  const { malls, layers, allowMapClick, polygonToCreate, showMallsNames } = state;

  const onPolygonClick = (layerType, mall) => e => {
    if (!allowMapClick || polygonToCreate) {
      return;
    }

    if (!layers.filter(item => item.type === layerType)[0].active) {
      return;
    }

    dispatch({
      type: 'onCreatePolygon',
      payload: {
        mode: 'edit',
        region_id: mall.id,
        layerType,
        region_name: mall.name,
        region_geometry: mall.geometry
      }
    });
  };

  const showMalls = useMemo(() => {
    return layers.filter(item => item.type === 'malls')[0].visible;
  }, [layers]);

  const opacity = polygonToCreate ? 0.2 : 0.5;
  const strokeOpacity = polygonToCreate ? 0.2 : 1;
  const color = '#f032e6';

  const mallsToRender = malls.map((mall, i) => {
    const positions = mall.geometry.coordinates.map(item => {
      return item.map(pointArr => pointArr.map(point => L.latLng(point[1], point[0])));
    });
    return (
      <Polygon
        pmIgnore={true}
        key={`${mall.id}-${i}`}
        positions={positions}
        fillColor={color}
        fillOpacity={opacity}
        color={color}
        opacity={strokeOpacity}
        weight={2}
        onClick={onPolygonClick('malls', mall)}
      >
        {showMallsNames && (
          <Tooltip className={'toolipClass'} permanent={true} direction={'center'}>
            {mall.name}
          </Tooltip>
        )}
      </Polygon>
    );
  });

  return showMalls ? <Pane style={{ zIndex: props.zIndex }}>{mallsToRender}</Pane> : null;
});

export default PaneRegionGroups;
