import axios from 'axios';
import Crypto from 'crypto-js';
import L from 'leaflet';

const convertFromLatLngToCoordPoint = latLng => {
  return [latLng.lat, latLng.lng];
};

export const convertFromCoordPointToLatLng = arr => {
  try {
    return L.latLng(arr[0], arr[1]);
  } catch (error) {
    return undefined;
  }
};

export const savePlans = async (state, dispatch, typeOfSaving = 'save', uuid) => {
  
  const { data, azimuth, zoom } = state;
  const planSetToSave = { ...data };
  const newPlans = planSetToSave.plans.map(plan => {
    const relevantPlan = state.plans.filter(item => item.uuid === plan.uuid)[0];
    if (typeOfSaving === 'anchor') {
      relevantPlan.isAnchor = uuid === relevantPlan.uuid ? true : false;
    }
    return {
      ...plan,
      layers: {
        ...plan.layers,
        geo_mapping_layer: {
          azimuth,
          prev_scale: relevantPlan.prev_scale,
          plan_center: convertFromLatLngToCoordPoint(relevantPlan.center),
          plan_top_left: convertFromLatLngToCoordPoint(relevantPlan.corners[0]),
          plan_top_right: convertFromLatLngToCoordPoint(relevantPlan.corners[1]),
          plan_bottom_left: convertFromLatLngToCoordPoint(relevantPlan.corners[2]),
          plan_bottom_right: convertFromLatLngToCoordPoint(relevantPlan.corners[3]),
          is_anchor: relevantPlan.isAnchor,
          zoom_level: zoom
        }
      }
    };
  });
  planSetToSave.plans = [...newPlans];

  console.log('[[[[[', planSetToSave);
  

  const planSetFormData = new FormData();
  planSetFormData.set('data', JSON.stringify(planSetToSave));
  
  try {
    const response = await axios.post(
      `https://maps.getshopster.net/geometry/save_plan_set/${state.objectUUID}/`,
      planSetFormData
    );
    typeOfSaving === 'save'
      ? dispatch({
          type: 'SAVE_PLANS',
          payload: await response.data.status
        })
      : dispatch({
          type: 'ANCHOR_CHANGE',
        });
  } catch (error) {
    dispatch({
      type: 'SAVE_PLANS',
      payload: 'error'
    });
    console.log(error);
  }
};

const colors = {
  getHeatColor: function(val) {
    var x = 2.0 / (1 + Math.exp(-val * 5)) - 1;
    var r = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * (x - 0.5))), 1);
    var g = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * (x - 0.25))), 1);
    var b = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * x)), 1);
    return 'rgba(' + parseInt(r * 255) + ',' + parseInt(g * 255) + ',' + parseInt(b * 255) + ',0.5)';
  },
  HSV2RGB: function(h, s, v) {
    var r, g, b, i, f, p, q, t;
    // if (arguments.length === 1) {
    //     s = h.s, v = h.v, h = h.h;
    // }
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
      case 0:
        r = v;
        g = t;
        b = p;
        break;
      case 1:
        r = q;
        g = v;
        b = p;
        break;
      case 2:
        r = p;
        g = v;
        b = t;
        break;
      case 3:
        r = p;
        g = q;
        b = v;
        break;
      case 4:
        r = t;
        g = p;
        b = v;
        break;
      case 5:
        r = v;
        g = p;
        b = q;
        break;
      default:
        break;
    }
    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    };
  },
  RGB2HEX: function(r, g, b) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;
    return '#' + (r + g + b).toUpperCase();
  }
};

export const generateColorForObjects = (str) => {
  const hash = Crypto.MD5(str.toString()).toString();
  const hash_hue = parseInt(hash.slice(0, 6), 16) / 65536;
  const hue = hash_hue; //Math.random();
  const saturation = 1;
  const lightness = 1;
  const c = colors.HSV2RGB(hue, saturation, lightness);
  return colors.RGB2HEX(c.r, c.g, c.b);
};

export const _rotateBy = (prevCorners, angle, crs, zoom) => {
  let crs_center = crs.latLngToPoint(calculateMidPoint(prevCorners), zoom),
    i,
    p,
    newCorners = [],
    q;

  for (i = 0; i < 4; i++) {
    p = crs.latLngToPoint(prevCorners[i], zoom).subtract(crs_center);
    q = new L.Point(Math.cos(angle) * p.x - Math.sin(angle) * p.y, Math.sin(angle) * p.x + Math.cos(angle) * p.y);
    newCorners[i] = crs.pointToLatLng(q.add(crs_center), zoom);
  }
  return newCorners;
};

export const calculateMidPoint = corners => {
  const fi1 = toRadians(corners[0].lat);
  const lambda1 = toRadians(corners[0].lng);
  const fi2 = toRadians(corners[3].lat);
  const lambda2 = toRadians(corners[3].lng);

  const Bx = Math.cos(fi2) * Math.cos(lambda2 - lambda1);
  const By = Math.cos(fi2) * Math.sin(lambda2 - lambda1);

  const fi3 = toDegrees(
    Math.atan2(Math.sin(fi1) + Math.sin(fi2), Math.sqrt((Math.cos(fi1) + Bx) * (Math.cos(fi1) + Bx) + By * By))
  );
  const lambda3 = toDegrees(lambda1 + Math.atan2(By, Math.cos(fi1) + Bx));

  return L.latLng(fi3, lambda3);
};

export const toRadians = x => (x * Math.PI) / 180;

export const toDegrees = radians => radians * (180 / Math.PI);

export const calculateCorners = (map, plan) => {
  try {
    const { x, y } = map._size;
    const { scale, width, height } = plan;
    // console.log('scale -----> ',scale);
    
    const mapXmeters = map.containerPointToLatLng([0, y]).distanceTo(map.containerPointToLatLng([x, y]));
    const mapScale = x / mapXmeters;
    const scaledPictureWidth = (width / scale) * mapScale;
    const scaledPictureHeight = (height / scale) * mapScale;
    const leftUpper = map.containerPointToLatLng(L.point(x / 4, y / 4));
    const rightUpper = map.containerPointToLatLng(L.point(x / 4 + scaledPictureWidth, y / 4));
    const rightLower = map.containerPointToLatLng(L.point(x / 4 + scaledPictureWidth, y / 4 + scaledPictureHeight));
    const leftLower = map.containerPointToLatLng(L.point(x / 4, y / 4 + scaledPictureHeight));
    return [leftUpper, rightUpper, leftLower, rightLower];
  } catch (error) {}
};

export const calculateAzimuth = corners => {
  const y = Math.sin(toRadians(corners[1].lng) - toRadians(corners[3].lng)) * Math.cos(toRadians(corners[1].lat));
  const x =
    Math.cos(toRadians(corners[3].lat)) * Math.sin(toRadians(corners[1].lat)) -
    Math.sin(toRadians(corners[3].lat)) *
      Math.cos(toRadians(corners[1].lat)) *
      Math.cos(toRadians(corners[1].lng) - toRadians(corners[3].lng));
  const azimuth = toDegrees(Math.atan2(y, x));
  return azimuth;
};


export const parseQuery = queryString => {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};
