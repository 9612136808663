import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import Context from '../context';
import { savePlans } from '../../tools';

const ModalOverlay = styled.div`
  position: absolute;
  display: ${props => (props.show.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 2000;
`;

const Modal = styled.div`
  padding: 20px;
  border-radius: 5px;
  background-color: #ccc;
  width: 300px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  /* height: 200px; */
`;

const Button = styled.div`
  padding: 5px;
  border: 1px solid #777;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  width: 100px;
  height: 30px;
  margin: 5px;
  cursor: pointer;
  &:hover {
    background-color: #bbb;
  }
  &:active {
    background-color: #aaa;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
`;

export default function Warning() {
  const { state, dispatch } = useContext(Context);
  const handleSubmitClick = (currState, currDispatch, type, uuid) => {
    savePlans(currState, currDispatch, type, uuid);
    dispatch({
      type: 'CLOSE_WARNING_MODAL'
    });
  };

  return (
    <ModalOverlay
      onClick={() =>
        dispatch({
          type: 'CLOSE_WARNING_MODAL'
        })
      }
      show={state.showWarningModal}
    >
      <Modal>
        <b>WARNING!</b>
        <p>An anchor layer change will cause the current position saving and the window reloading.</p>
        <ButtonsContainer>
          <Button
            onClick={() =>
              dispatch({
                type: 'CLOSE_WARNING_MODAL'
              })
            }
          >
            Cancel
          </Button>
          <Button onClick={() => handleSubmitClick(state, dispatch, 'anchor', state.showWarningModal.planToBeAnchor)}>OK</Button>
        </ButtonsContainer>
      </Modal>
    </ModalOverlay>
  );
}
