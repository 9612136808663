import React from 'react';

const SpacesContext = React.createContext({
  layers: [
    { id: 1, name: 'Project Locations', type: 'project_locations', visible: false, active: false },
    { id: 2, name: 'Regions', type: 'region_groups', visible: false, active: false },
    { id: 3, name: 'Malls', type: 'malls', visible: false, active: false }
  ],
  projectLocationsByPlType: { All: { visible: false, active: false } },
  regionGroups: [],
  malls: [],
  polygonToCreate: undefined,
  groupToEdit: undefined,
  allowMapClick: true,
  overlayModal: { show: false },
  changePlRadius: true,
  plOpacity: 0.5,
  showMallsNames: false,
  currentZoom: 11,
  currentBounds: undefined,

  activeLayerId: null,
  test: false,
  polygonsCatalogModal: {
    show: false,
    polygons: {}
  },
  searchValue: '',
  addNew: {},
  cursorCoords: {},
  isPickCursorCoords: false,
  saveStatus: 'none',
  token: '',

  outdoorSpacesData: [],
  outdoorIPointsData: [],
  selectedPL: [],
  innerData: {},
  allCenters: [],
  spacesTypesCatalog: [],
  showIPoints: true,
  selectedSpaces: [],
  initialZoom: 11,
  thresholdZoomLevel: 14,
  renderMode: 'markers',
  mapCenter: { lat: 55.751768, lng: 37.61714 },
  contextMenu: {
    show: false,
    x: 0,
    y: 0,
    routerInfo: {},
    typeOfRouter: ''
  },
  editModal: {
    show: false,
    id: null,
    typeOfRouter: '',
    selectedPlId: undefined,
    selectedPlName: undefined
  },
  colors: [
    '#ffe119',
    '#4363d8',
    '#46f0f0',
    '#e6194b',
    '#3cb44b',
    '#f58231',
    '#911eb4',
    '#f032e6',
    '#bcf60c',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#000075'
  ]
});

export default SpacesContext;
