import React, { useContext, useRef } from 'react';
import styled from 'styled-components/macro';
import cn from 'classnames';
import Context from '../context';
import '../styles/index.css';

const Form = styled.form`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    width: 100px;
    margin-left: 10px;
`;

const Modes = ({ plan }) => {
    const { dispatch } = useContext(Context);

    const onModeChange = (uuid) => (e) => {
        dispatch({
            type: 'MODE_CHANGE',
            payload: {
                uuid,
                value: e.target.value,
            },
        });

        e.target.blur();
    };

    const modes = plan.isAnchor ? ['lock', 'translate', 'rotate'] : ['lock', 'translate'];

    return (
        <Form>
            {modes.map((item, i) => {
                const CheckmarkClass = cn({
                    ModesCheckmark: true,
                    ModesRotate: item === 'rotate',
                    ModesTranslate: item === 'translate',
                    ModesLock: item === 'lock',
                });
                return (
                    <div className={'ModesContainer'} key={`mode-${i}`}>
                        <label>
                            <input
                                checked={plan.editMode === item}
                                onChange={onModeChange(plan.uuid)}
                                type="radio"
                                name={`mode${plan.floor}`}
                                value={item}
                            />
                            <span className={CheckmarkClass} />
                        </label>
                    </div>
                );
            })}
        </Form>
    );
};

export default Modes;
