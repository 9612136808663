import React from 'react';

const SpacesContext = React.createContext({
    activeLayerId: null,
    test: false,
    currentZoom: 11,
    polygonsCatalogModal: {
        show: false,
        polygons: {}
    },
    searchValue: '',
    addNew: {},
    cursorCoords: {},
    isPickCursorCoords: false,
    saveStatus: 'none',
    token: '',
    allowEditCatalog: {
        adSpaces: {
            name: {
                field: 'input',
                type: 'text',
                defaultValue: ''
            },
            ext_id: {
                field: 'input',
                type: 'text',
                defaultValue: ''
            },
            ad_space_type: {
                field: 'select',
                defaultValue: {
                    category: 'billboard_3x6',
                    height: 3,
                    id: 18,
                    name: 'Billboard 3х6',
                    subcategory: 'board',
                    view_angle: 120,
                    view_max_distance: 150,
                    width: 6
                }
            },
            latitude: {
                field: 'input',
                type: 'number',
                step: 0.00001,
                defaultValue: 55.790007
            },
            longitude: {
                field: 'input',
                type: 'number',
                step: 0.00001,
                defaultValue: 49.219969
            },
            orientation_azimuth: {
                field: 'input',
                type: 'number',
                step: 1,
                defaultValue: 90
            }
        },
        iPoints: {
            marker: {
                field: 'input',
                type: 'text',
                defaultValue: ''
            },
            latitude: {
                field: 'input',
                type: 'number',
                step: 0.00001,
                defaultValue: 55.790007
            },
            longitude: {
                field: 'input',
                type: 'number',
                step: 0.00001,
                defaultValue: 49.219969
            },
            orientation_azimuth: {
                field: 'input',
                type: 'number',
                step: 1,
                defaultValue: 90
            },
            is_directed: {
                field: 'input',
                type: 'checkbox',
                defaultValue: true
            }
        }
    },
    outdoorSpacesData: [],
    outdoorIPointsData: [],
    projectLocationsData: [],
    selectedPL: [],
    innerData: {},
    allCenters: [],
    spacesTypesCatalog: [],
    showIPoints: true,
    selectedSpaces: [],
    initialZoom: 11,
    thresholdZoomLevel: 14,
    renderMode: 'markers',
    mapCenter: { lat: 55.751768, lng: 37.61714 },
    contextMenu: {
        show: false,
        x: 0,
        y: 0,
        routerInfo: {},
        typeOfRouter: ''
    },
    editModal: {
        show: false,
        id: null,
        typeOfRouter: '',
        selectedPlId: undefined,
        selectedPlName: undefined
    }
});

export default SpacesContext;
