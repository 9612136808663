import React, { useContext, useMemo } from 'react';
import { Pane, Circle, LayerGroup, Tooltip } from 'react-leaflet';
import context from '../../context';

import '../../leaflet/leaflet.css';

const ZOOM_THRESHOLD = 11;
const MULTIPLIER = 0.2;

const PanePl = React.memo(props => {
  const { state } = useContext(context);
  const { projectLocationsByPlType, layers, changePlRadius, plOpacity } = state;

  const zoom = !changePlRadius || props.currentZoom > ZOOM_THRESHOLD ? props.currentZoom + 8 : ZOOM_THRESHOLD + 8;

  

  const metresPerPixel =
    (40075016.686 *
      Math.abs(Math.cos((props.mapRef.current.leafletElement.getCenter().lat / 180) * Math.PI))) /
    Math.pow(2, zoom);

  const showLocationsPane = useMemo(() => {
    return layers.filter(item => item.type === 'project_locations')[0].visible;
  }, [layers]);

  const locations = (data, plType, color) => {
    return (
      <LayerGroup key={`PlLayerGroup - ${plType}`}>
        {Object.keys(data).map((key, i) => {
          const item = data[key];
          const radius = 8 * metresPerPixel;
          const tooltip = `${item.id} | ${item.pl_type} | ${item.name}`;
          return (
            <Circle
              pmIgnore={true}
              key={`${plType}-c-${item.latitude}-${item.longitude}-${i}`}
              id={item.id}
              center={{ lat: Number(item.latitude), lng: Number(item.longitude) }}
              fillColor={color}
              fillOpacity={plOpacity}
              color={'transparent'}
              radius={radius}
              // onContextMenu={onRightClick(item.id, typeOfRouter)}
              // onClick={onCircleClick(item.id, typeOfRouter)}
            >
              <Tooltip key={i}>{tooltip}</Tooltip>
            </Circle>
          );
        })}
      </LayerGroup>
    );
  };

  const panes = () => {
    return Object.keys(projectLocationsByPlType)
      .filter(key => key !== 'All')
      .filter(key => projectLocationsByPlType[key].visible)
      .map(key => {
        return locations(
          projectLocationsByPlType[key].data,
          key,
          projectLocationsByPlType[key].color
        );
      });
  };

  return showLocationsPane ? <Pane style={{ zIndex: props.zIndex }}>{panes()}</Pane> : null;
});

export default PanePl;
