export const arrayToCSV = objArray => {
    
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str =
        `${Object.keys(array[0])
            .map(value => `${value}`)
            .join(' ')}` + '\r\n';

    return array.reduce((str, next) => {
        str +=
            `${Object.values(next)
                .map(value => `${value}`)
                .join(';')}` + '\r\n';
        return str;
    }, str);
};

export const getPointParams = (item, typeOfRouter) => {
    const result = {};
    switch (typeOfRouter) {
        case 'adSpaces':
            result.polygonColor = 'blue';
            result.circleColor = 'blue';
            result.radius = 20;
            result.tooltip = `AdSpace | ${item.name} | ${item.ad_space_type.name}`;
            break;
        case 'iPoints':
            result.polygonColor = '#FFA000';
            result.circleColor = '#FFA000';
            result.radius = 15;
            result.tooltip = `iPoint | ${item.marker} | ${item.id}`;
            break;
        default:
            break;
    }
    return result;
};

export const generatePolygon = (map, item, typeOfRouter, isInner, mode) => {
    const defaultTheta = 30;
    const defaultIPointMaxDistance = 250;

    const currentMap = map.current.leafletElement;
    const metresPerPixel =
        (40075016.686 * Math.abs(Math.cos((currentMap.getCenter().lat / 180) * Math.PI))) / Math.pow(2, currentMap.getZoom() + 8);

    const makeSectorPoint = (point, distance, index) => {
        return {
            x: point.x + distance * Math.cos((index * Math.PI) / 180),
            y: point.y + distance * Math.sin((index * Math.PI) / 180)
        };
    };

    const { polygonColor, tooltip, radius } = getPointParams(item, typeOfRouter);
    let theta = 30;
    if (item.view_angle) {
        theta = item.view_angle;
    } else if (mode === 'addNew' && typeOfRouter === 'adSpaces') {
        theta = item.ad_space_type.view_angle;
    } else if (!item.is_directed || item.orientation_azimuth === null) {
        theta = 360;
    } else {
        theta = defaultTheta;
    }

    const azimuth = item.orientation_azimuth !== null ? (item.orientation_azimuth % 360) - 90 : 90;
    // const azimuth = item.orientation_azimuth ? (item.orientation_azimuth % 360) - 90 : (item.direction % 360) - 90;
    const iterationAngle = 3;
    let maxDistance = 0;
    if (isInner) {
        maxDistance = radius / metresPerPixel;
    } else {
        maxDistance =
            item.view_max_distance && typeOfRouter === 'iPoints'
                ? item.view_max_distance / metresPerPixel
                : typeOfRouter === 'adSpaces'
                ? item.ad_space_type.view_max_distance / metresPerPixel
                : !item.is_directed || item.orientation_azimuth === null
                ? defaultIPointMaxDistance / 4 / metresPerPixel
                : defaultIPointMaxDistance / metresPerPixel;
    }
    // console.log(maxDistance);

    const center = [Number(item.latitude), Number(item.longitude)];
    const point = currentMap.latLngToContainerPoint(center);
    let a1 = [];
    let a2 = [];

    for (let index = azimuth + theta / 2; index > azimuth; index = index - iterationAngle) {
        const a = makeSectorPoint(point, maxDistance, index);
        a1.push(Object.values(currentMap.containerPointToLatLng(a)));
    }
    for (let index = azimuth - theta / 2; index < azimuth; index = index + iterationAngle) {
        const a = makeSectorPoint(point, maxDistance, index);
        a2.push(Object.values(currentMap.containerPointToLatLng(a)));
    }
    const polygon = [center, ...a1, ...a2.reverse(), center];

    return {
        id: item.id,
        name: item.name,
        orientation_azimuth: item.orientation_azimuth,
        polygon,
        polygonColor,
        tooltip
    };
};
