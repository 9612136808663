import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Plans from './plans';
import Context from '../context';
import Warning from './warning';
import { savePlans } from '../../tools';

const Container = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: #eee;
  width: 300px;
  padding: 10px;
  > input {
    width: 100%;
    box-sizing: border-box;
    height: 30px;
    padding: 5px;
    font-size: 16px;
  }
  h4 {
    margin: 20px 0 10px 0;
    font-size: 18px;
    font-weight: normal;
    padding: 0;
  }
`;

const Button = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #777;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  font-size: 18px;
  background-color: #ccc;
  :hover {
    background-color: #bbb;
  }
  :active {
    background-color: #999;
  }
`;

const SaveButton = styled.div`
  margin: 20px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #777;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  font-size: 18px;
  background-color: ${props =>
    props.status === 'saved' ? 'rgb(108, 254, 144)' : props.status === 'error' ? 'rgb(254, 174, 108)' : '#ccc'};
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > input {
    flex-shrink: 0;
    width: 180px;
    box-sizing: border-box;
    height: 30px;
    padding: 5px;
    font-size: 16px;
    margin-left: 10px;
  }
`;

export default function ControlPanel() {
  const { state, dispatch } = useContext(Context);

  const [uuid, setUuid] = useState('');

  const handleUuidChange = e => {
    dispatch({
      type: 'UUID_CHANGE',
      payload: e.target.value
    });
  };

  const handleCenterLatChange = e => {
    dispatch({
      type: 'CENTER_LAT_CHANGE',
      payload: e.target.value
    });
  };

  const handleCenterLngChange = e => {
    dispatch({
      type: 'CENTER_LNG_CHANGE',
      payload: e.target.value
    });
  };

  useEffect(() => {
    setUuid(prev => state.objectUUID);
  }, [state.objectUUID]);

  const cornersMap = { 0: 'left-upper', 1: 'right-upper', 2: 'left-lower', 3: 'right-lower' };
  return (
    <Container>
      {state.plans.length > 1 && <Warning />}
      <Plans />
      <h4>UUID:</h4>
      <input value={uuid} onChange={e => handleUuidChange(e)} type={'text'} />
      
      <h4>Map's center coordinates:</h4>
      <Label>
        latitude:
        <input value={state.mapCenter.lat} onChange={e => handleCenterLatChange(e)} type={'text'} />
      </Label>
      <Label>
        longitude:
        <input value={state.mapCenter.lng} onChange={e => handleCenterLngChange(e)} type={'text'} />
      </Label>
      
      {/* <Button
        onClick={() =>
          dispatch({
            type: 'SHOW_PLANS',
            payload: true
          })
        }
        >
        {'show plans'}
      </Button> */}
      {state.map && state.map.current && <h4>{`Current Zoom: ${state.map.current.leafletElement._zoom}`}</h4>}
      
      <h4>{`Azimuth: ${Number(state.azimuth.toFixed(2))}`}</h4>
      
      {/* <h4>Corners Coordinates</h4>
      <div>
        {state.plans &&
          state.plans
            .sort((a, b) => b.floor - a.floor)
            .map((item, index) => {
              return (
                <div key={`plan ${index}`}>
                  <h4>{`${item.floor} floor`}</h4>
                  {item.corners.map((corner, i) => {
                    return <p key={`corner ${i}${index}`}>{`${cornersMap[i]} >> ${corner}`}</p>;
                  })}
                </div>
              );
            })}
      </div> */}
      <SaveButton status={state.status} onClick={() => savePlans(state, dispatch, 'save')}>
        {state.status}
      </SaveButton>
    </Container>
  );
}
