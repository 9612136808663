import React, { useState, useEffect, useReducer, useContext } from 'react';
import styled from 'styled-components/macro';
import axios from 'axios';
import Map from './components/map';
import ControlPanel from './components/control_panel';
import Context from './context';
import Reducer from './reducer';
import { parseQuery } from '../tools';

const Container = styled.div`
    display: flex;
    height: 100%;
`;

// custom hook
const useAPI = (endpoint, uuid) => {
    const [data, setData] = useState({});
    const [query, setQuery] = useState({});

    useEffect(() => {
        const query = parseQuery(window.location.search);
        setQuery(prev => query);
    }, []);

    useEffect(() => {
        getData(query);
    }, [query]);

    const getData = async query => {
        try {
            const currentUUID = query.plan_set_uuid ? query.plan_set_uuid : uuid;
            if (!currentUUID) return;
            const response = await axios.get(`${endpoint}${currentUUID}/`);
            setData(prev => response.data);
        } catch (error) {
            // console.log(error);
        }
    };

    return { data, query, uuid };
};

const GeoMap = () => {
    const initialState = useContext(Context);
    const [state, dispatch] = useReducer(Reducer, initialState);
    const savedData = useAPI(`https://maps.getshopster.net/geometry/plan_set/`, state.objectUUID);

    useEffect(() => {
        if (Object.keys(savedData).length !== 0) {
            dispatch({
                type: 'GET_DATA',
                payload: savedData
            });
        }
    }, [savedData.data]);

    useEffect(() => {
        if (Object.keys(state.data).length === 0) return;
        // timeout из-за cors 
        setTimeout(
            () =>
                dispatch({
                    type: 'SHOW_PLANS',
                    payload: true
                }),
            2000
        );
    }, [state.data]);

    return (
        <Context.Provider value={{ state, dispatch }}>
            <Container>
                <ControlPanel />
                <Map />
            </Container>
        </Context.Provider>
    );
};

export default GeoMap;
